<template>
    <div class="w-100 step-confirmation text-left">
        <loading-data v-if="isLoading" size="medium" txt="Cargando información"></loading-data>

        <div class="row justify-content-center" v-else-if="!isLoading && order">
            
            <div class="col-sm-12 mb-3 info-section">
                <div v-if="order.payment_method === 'oxxo' || order.payment_method === 'spei'" class="message-alert bg-info">
                    <p class="mb-2">Hemos generado <strong>{{ order.payment_method === 'spei' ? 'el # de referencia' : 'la clabe' }}</strong> para que puedas realizar el pago, sigue las instrucciones que a continuación se listan en la parte de <strong>Datos de pago</strong>.</p>
                    <p>Una vez confirmado el pago te enviaremos un correo con tu confirmación del pedido y te estaremos notificando sobre el estatus de éste.</p>
                </div>

                <div v-if="order.payment_method === 'card' && order.payment_status === 'completed'" class="message-alert bg-success">
                    <p>¡Gracias por tu compra!, tu pago se procesó correctamente, te enviamos un correo con la confirmación de tu pedido y te estaremos notificando sobre el estatus de éste.</p>
                </div>    
            </div>

            <div class="col-sm-12 mb-3 info-section">
                <h4>Cliente</h4>
                <p>{{ order.client.name }}</p>
            </div>
            <div class="col-sm-12 mb-3 info-section">
                <h4>Datos de contacto</h4>
                <p><span class="font-weight-bold">Tel.:</span> {{ order.client.mobile }}</p>
                <p><span class="font-weight-bold">Email.:</span> {{ order.client.email }}</p>
            </div>
            <div class="col-sm-12 mb-3 info-section">
                <h4>Datos de envío</h4>
                <p><span class="font-weight-bold">Enviar a:</span> {{ order.address.place }}</p>
                <p><span class="font-weight-bold">Dirección:</span> {{ order.address.address + ', ' + order.address.neighborhood + ', ' + order.address.delmun + ', C.P.' + order.address.zip_code + ', ' + order.address.city + ', ' + order.address.state }}</p>
            </div>
            <div class="col-sm-12 mb-3 info-section">
                <h4>Datos de pago</h4>
                <div class="cont-img">
                    <img :src="'/images/web/' + order.payment_method + '.png'" width="90">
                </div>
                <p><span class="font-weight-bold">Estatus:</span> <span class="text-primary">{{ order.payment_status === 'pending' ? 'Pendiente de pago' : order.payment_status === 'completed' ? 'Pagado' : 'Rechazado' }}</span></p>

                <template v-if="order.payment_method === 'oxxo' || order.payment_method === 'spei'">
                    <p><span class="font-weight-bold">Referencia:</span> <span class="text-primary">{{ order.payment_reference }}</span></p>
                    <p><span class="font-weight-bold">Total:</span> <span class="text-primary">{{ order.total }}</span></p>
                    <p><span class="font-weight-bold">Instrucciones:</span></p>
                    <ol>
                        <li v-for="(instruction, keyInst) in instructions[order.payment_method].split('|')" :key="keyInst">{{ instruction }}</li>
                    </ol>
                </template>

            </div>
        </div>

        <template v-else>
            <no-information-component title="Carrito vacio" text="No has agregado ningun elemento a tu carrito"></no-information-component>
           
            <div class="col-12 mt-2">
                <a href="/tienda/index" class="btn btn-outline-secondary">Ir a la tienda</a>
            </div>
        </template>
    </div>
</template>
<script>
import LoadingDataComponent from '../../LoadingDataComponent';

export default {
    data() {
        return {
            isLoading: true,
            order: null,
            instructions: {
                spei: 'Accede a tu banca en línea.|Da de alta la CLABE en esta ficha. El banco deberá de ser STP.|Realiza la transferencia correspondiente por la cantidad exacta en esta ficha, de lo contrario se rechazará el cargo.|Al confirmar tu pago, el portal de tu banco generará un comprobante digital. En él podrás verificar que se haya realizado correctamente. Conserva el comprobante de pago que te genera el banco.',
                oxxo: 'Acude a la tienda OXXO más cercana.|Indica en caja que quieres realizar un pago de OXXOPay.|Dicta al cajero el número de referencia en esta ficha para que teclee directamete en la pantalla de venta.|Realiza el pago correspondiente con dinero en efectivo.|Al confirmar tu pago, el cajero te entregará un comprobante impreso. En él podrás verificar que se haya realizado correctamente. Conserva este comprobante de pago.'
            }
        }
    },
    mounted() {
        getRequestAjax('/api/orderInProcess')
            .done((res) => {
                if (res.data) {
                    this.order = res.data;
                    // console.log(this.order);
                    window.fbq('track', 'Purchase', {currency: "MXN", value: this.order.total });
                    window.ga('event', 'purchase', {"order_id": this.order.id, "value": this.order.total,"currency": "MXN"});
                } else {
                    this.order = null;
                }

                this.isLoading = false;
			})
			.fail((error) => {
				console.log('err');
				console.log(error);
                this.order = null;
                this.isLoading = false;

                if (error.status === 500) {
                    showAlert('Lo sentimos, hay un error de nuestro lado, estamos trabajando para solucionarlo.', 'danger');
                } else {
                    showAlert(error.responseJSON ? error.responseJSON : error.statusText, 'danger');
                }

			});
    },
    components: {
        loadingData: LoadingDataComponent
    }
}
</script>
<style lang="css">
    .message-alert {
        border-radius: 5px;
        padding: 10px;
    }
    .message-alert.bg-info {
        background-color: #d8ddec !important;
        border-color: #c8cfe4;
        color: #1e2c53;
    }
    .message-alert.bg-success {
        color: #1d643b;
        background-color: #d7f3e3 !important;
        border-color: #c7eed8;
    }
</style>