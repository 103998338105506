<template>
    <div class="w-100 step-address text-left">
        <div class="row">
            <div class="form-group color-secondary col-12">
                <label for="Direccion">Enviar a:</label>
                <select v-model="placeSelected" name="owner[address]" type="text" class="form-control" :class="{'is-invalid': mdlAddressError.placeSelected !== null}" v-on:change="onSelectAddress" :disabled="processData" required>
                    <option value="">Seleccione un lugar...</option>
                    <option v-for="(address, kAddress) in addresses" :key="kAddress" :value="address.address_id">{{ address.place }}</option>
                    <option value="other">Nueva dirección</option>
                </select>
                <div class="invalid-feedback">{{mdlAddressError.placeSelected}}</div>
            </div>
       
            <div class="form-group color-secondary col-12" >
                <label for="place">¿Qué lugar es?<sup class="required">*</sup></label>
                <input v-model="mdlAddress.place" name="owner[place]" type="text" placeholder="Mi casa" class="form-control" :class="{'is-invalid': mdlAddressError.place !== null}" id="place" :disabled="processData" required>
                <div class="invalid-feedback">{{mdlAddressError.place}}</div>
            </div>

            <div class="form-group color-secondary col-12" >
                <label for="Direccion">Calle y Número<sup class="required">*</sup></label>
                <input v-model="mdlAddress.address" name="owner[address]" type="text" class="form-control" :class="{'is-invalid': mdlAddressError.address !== null}" id="Direccion" :disabled="processData" required>
                <div class="invalid-feedback">{{mdlAddressError.address}}</div>
            </div>
            <div class="form-group color-secondary col-md-6" >
                <label for="CP">Código Postal<sup class="required">*</sup></label>
                <input v-model="mdlAddress.zip_code" name="owner[zip_code]" type="number" class="form-control" :class="{'is-invalid': mdlAddressError.zip_code !== null}" id="CP" v-on:blur="onGetAddressCP" :disabled="processData" required>
                <div class="invalid-feedback">{{mdlAddressError.zip_code}}</div>
            </div>
            <div class="form-group color-secondary col-md-6" >
                <label for="Colonia">Colonia<sup class="required">*</sup></label>
                <input v-model="mdlAddress.neighborhood" name="owner[neighborhood]" type="text" class="form-control" :class="{'is-invalid': mdlAddressError.neighborhood !== null}" id="Colonia" :disabled="processData" required>
                <div class="invalid-feedback">{{mdlAddressError.neighborhood}}</div>
            </div>
            <div class="form-group color-secondary col-md-6" >
                <label for="Colonia">Alcaldía o Municipio<sup class="required">*</sup></label>
                <input v-model="mdlAddress.delmun" name="owner[delmun]" type="text"  class="form-control"  :class="{'is-invalid': mdlAddressError.delmun !== null}" :disabled="processData" id="DelMun">
                <div class="invalid-feedback">{{mdlAddressError.delmun}}</div>
            </div>
            <div class="form-group color-secondary col-md-6" >
                <label for="Ciudad">Ciudad<sup class="required">*</sup></label>
                <input v-model="mdlAddress.city" name="owner[city]" type="text"  class="form-control" :class="{'is-invalid': mdlAddressError.city !== null}" :disabled="processData" id="Ciudad">
                <div class="invalid-feedback">{{mdlAddressError.city}}</div>
            </div>
            <div class="form-group color-secondary col-md-6" >
                <label for="Estado">Estado<sup class="required">*</sup></label>
                <input v-model="mdlAddress.state" name="owner[state]" type="text"   class="form-control" :class="{'is-invalid': mdlAddressError.state !== null}" :disabled="processData" id="Estado">
                <div class="invalid-feedback">{{mdlAddressError.state}}</div>
            </div>

            <div v-if="placeSelected !== 'other'" class="form-group color-secondary col-md-12">
                <div  class="form-check form-check-inline">
                    <input v-model="saveChanges" class="form-check-input" type="checkbox" :disabled="processData" id="inlineCheckbox1">
                    <label class="form-check-label" for="inlineCheckbox1">Guardar cambios</label>
                </div>
                <small id="passwordHelpBlock" class="form-text text-muted">
                    Sólo en caso que cambiaras un dato de las direcciones existentes.
                </small>
                
            </div>


            <div class="col-sm-12 text-right">
                <button 
                    :disabled="processData"
                    v-on:click="onNextStep"
                    class="btn btn-secondary">

                    <loading-data v-if="processData" :display-inline="true" color="light" txt="Guardando"></loading-data> 
                    <span v-else>Siguiente</span>
                    </button>
            </div>
        </div>
    </div>
</template>
<script>
import LoadingDataComponent from '../../LoadingDataComponent.vue';

export default {
    props: { 
        processData: { type: Boolean, default: false}
    },
    data() {
        return {
            addresses: [],
            placeSelected: '',
            mdlAddress: {
                address: null,
                zip_code: null,
                neighborhood: null,
                city: null,
                delmun: null,
                state: null,
                place: null
            },
            mdlAddressError: {
                placeSelected: null,
                address: null,
                zip_code: null,
                neighborhood: null,
                city: null,
                delmun: null,
                state: null,
                place: null
            },
            saveChanges: false
        }
    },
    mounted() {
        getRequestAjax('/api/getAddresessUser')
            .done((res) => {
                this.addresses = res.data;
      
                if (this.addresses.length === 1) {
                    
                    this.placeSelected = this.addresses[0].address_id;

                    this.onSelectAddress();
                } else {
                    this.placeSelected = this.addresses.length ? '' : 'other';
                }
			})
			.fail((error) => {
				if (error.status === 500) {
                    showAlert('Lo sentimos, hay un error de nuestro lado, estamos trabajando para solucionarlo.', 'danger');
                } else {
                    showAlert(error.responseJSON ? error.responseJSON : error.statusText, 'danger');
                }
			});
    },
    methods: {
        onGetAddressCP() {
            if (this.placeSelected === 'other') {
                getRequestAjax('/api/cp', 'get', {cp : this.mdlAddress.zip_code})
                    .done((res) => {
                        if (typeof res[0]!="undefined") {
                            this.mdlAddress.city = res[0].city;
                            this.mdlAddress.delmun = res[0].delmun;
                            this.mdlAddress.state = res[0].state;
                            this.mdlAddress.neighborhood = res[0].col;
                        }
                    })
                    .fail((err) => {
                        console.log('err');
                        console.log(err);
                    });
            }
        },
        onSelectAddress() {
            if (this.placeSelected !== 'other') {
                const address = this.addresses.find(ad => ad.address_id === this.placeSelected);

                if (address !== undefined) {
                    this.mdlAddress.address = address.address;
                    this.mdlAddress.zip_code = address.zip_code;
                    this.mdlAddress.neighborhood = address.neighborhood;
                    this.mdlAddress.city = address.city;
                    this.mdlAddress.delmun = address.delmun;
                    this.mdlAddress.state = address.state;
                    this.mdlAddress.place = address.place;
                }
            } else {
                this.saveChanges = false;

                this.mdlAddress.address = null;
                    this.mdlAddress.zip_code = null;
                    this.mdlAddress.neighborhood = null;
                    this.mdlAddress.city = null;
                    this.mdlAddress.delmun = null;
                    this.mdlAddress.state = null;
                    this.mdlAddress.place = null;
            }
        },
        onNextStep() {
            let errors = 0;

            if ( this.placeSelected === null || this.placeSelected.trim() === '' ) {
                this.mdlAddressError.placeSelected = 'Seleccione un lugar de envío o agregue uno nuevo';
                errors++;
            } else {
                this.mdlAddressError.placeSelected = null;
            }

            if ( this.mdlAddress.place === null || this.mdlAddress.place.trim() === '' ) {
                this.mdlAddressError.place = 'Ingrese un identificador del lugar de envío por favor';
                errors++;
            } else {
                this.mdlAddressError.place = null;
            }

            if ( this.mdlAddress.address === null || this.mdlAddress.address.trim() === '' ) {
                this.mdlAddressError.address = 'Agregue una dirección por favor';
                errors++;
            } else {
                this.mdlAddressError.address = null;
            }

            if ( this.mdlAddress.city === null || this.mdlAddress.city.trim() === '' ) {
                this.mdlAddressError.city = 'Ingrese la Ciudad por favor';
                errors++;
            } else {
                this.mdlAddressError.address = null;
            }

            if ( this.mdlAddress.zip_code === null || this.mdlAddress.zip_code.trim() === '' ) {
                this.mdlAddressError.zip_code = 'Ingrese el C.P. por favor';
                errors++;
            } else {
                this.mdlAddressError.zip_code = null;
            }

            if ( this.mdlAddress.neighborhood === null || this.mdlAddress.neighborhood.trim() === '' ) {
                this.mdlAddressError.neighborhood = 'Ingrese la Colonia por favor';
                errors++;
            } else {
                this.mdlAddressError.neighborhood = null;
            }

            if ( this.mdlAddress.delmun === null || this.mdlAddress.delmun.trim() === '' ) {
                this.mdlAddressError.delmun = 'Ingrese la Alcaldía o Municipio por favor';
                errors++;
            } else {
                this.mdlAddressError.delmun = null;
            }

            if ( this.mdlAddress.state === null || this.mdlAddress.state.trim() === '' ) {
                this.mdlAddressError.state = 'Ingrese el Estado por favor';
                errors++;
            } else {
                this.mdlAddressError.state = null;
            }

            if (errors === 0) {
                const obj = {
                    isNew: this.placeSelected === 'other' ? 1 : 0,
                    address_id: this.placeSelected === 'other' ? null : this.placeSelected,
                    address: JSON.stringify(this.mdlAddress),
                    saveChanges: this.saveChanges ? 1 : 0
                }

                this.$emit('nextStep', {step: 'shipping', data: obj});
            } 
        },
    },
    components: {
        loadingData: LoadingDataComponent
    }
}
</script>