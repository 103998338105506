<template>
    <div class="modal fade" id="mdlPlan" tabindex="-1" aria-labelledby="mdlPlanLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal modal-plane-care">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">

                    <div class="row text-left">

                        <div class="col-sm-12 col-md-6 cont-img">
                            <img class="img-plan plan-img-large" :src="'/images/compras/' + item.image_small">
                        </div>

                        <div class="col-sm-12 col-md-6 cont-info">
                            <h2 class="title plan-name">{{ item.name }}</h2>
                            <h4 v-if="item.text_price_other != ''" class="price plan-price-text text-center" style="text-decoration: line-through;">{{ item.text_price_other }}</h4> 
                            <h4 class="price plan-price-text text-center">{{ item.text_price }}</h4>

                        </div>
                    </div>

                    <div class="row text-left">

                        <div class="col-sm-12 col-md-6 offset-md-3 mt-md-4 cont-img">
                            
                            <div class="tab-pane fade show active" id="first" role="tabpanel" aria-labelledby="first-tab">
                                <form class="frm-options">
                                    <div class="form-group text-center">
                                        <label for="">Selecciona el color: <span class="plan-color-text">{{ selectedColor }}</span></label>
                                        <div class="cont-colors">
                                            <div v-for="(color, keyColor) in colors" :key="keyColor" class="cont-color" :class="{ 'active-color': color.color === selectedColor }" v-on:click="selectedColor = color.color">
                                                <div :data-color="color.color" class="plan-color color" :class="[color.css]"></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group mb-0 text-center">
                                        <label for="">Cantidad:</label>
                                    </div>
                                    <div class="form-row cont-qty text-center">

                                        <div class="form-group col">
                                            <button type="button" class="btn btn-edit-qty btn-light" v-on:click="onEditQtyBtn('subtract')">-</button>
                                        </div>

                                        <div class="form-group col">
                                            <input v-model="qtyValue" type="number" min="0" step="1" class="form-control input-qty" v-on:input="onInputQty">
                                        </div>

                                        <div class="form-group col">
                                            <button type="button" class="btn btn-edit-qty btn-light" v-on:click="onEditQtyBtn('plus')">+</button>
                                        </div>
                                    </div>

                                    <div class="form-group mt-3">
                                        <button :disabled=" (disabledBtns || (qtyValue === '' || qtyValue <= 0))" type="button" class="btn btn-success btn-block btn-add-cart" v-on:click="onAddCart('checkout')">
                                            Agregar y finalizar compra <br> <span class="plan-total text-light">$ {{ formatMoney(+item.price * qtyValue) }}</span>
                                        </button>

                                        <button :disabled=" (disabledBtns || (qtyValue === '' || qtyValue <= 0))" type="button" class="btn btn-block btn-link text-secondary btn-add-cart" v-on:click="onAddCart('continue')">
                                            Agregar y seguir comprando
                                        </button>
                                    </div>

                                </form>

                            </div>
                            <div class="tab-pane fade" id="second" role="tabpanel" aria-labelledby="second-tab">
                                <ul class="plan-features">
                                    <li v-for="(feature, keyFeature) in item.features" :key="keyFeature">{{ feature.feature }}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import EventBus from '../../bus';
import LoadingDataComponent from '../LoadingDataComponent.vue';

export default {
    props: {
        item: {type: Object, required: true},
    },
    data() {
        return {
            selectedColor: 'Negro',
            colors: [
                { color: 'Negro', css: 'black' },
                { color: 'Rosa', css: 'pink' },
                { color: 'Azul', css: 'blue' },
                { color: 'Verde', css: 'green' }    
            ],
            qtyValue: 1,
            disabledBtns: false
        }
    },
    methods: {
        onEditQtyBtn(action) {
            if ( action == 'subtract' ) {
			    if (this.qtyValue == 1) return;
                else this.qtyValue--;
            } else if ( action == 'plus' ) {
                this.qtyValue++;
            } else {
                return;
            }
        },
        onInputQty() {
            this.qtyValue = +this.qtyValue;
        },
        onAddCart(action) {
            if (this.qtyValue === '' || this.qtyValue <= 0) return;

            this.disabledBtns = true;

            const obj = {
                action: 'add',
                data: JSON.stringify({
                    product_id: this.item.service_id,
                    type: 'service',
                    detail: JSON.stringify({ color: this.selectedColor }),
                    qty: this.qtyValue,
                })
            };

            getRequestAjax('/api/updateShopping', 'post', obj)
                .done((res) => {
                    if (res.data) {
                        EventBus.$emit('update-cart', res.data);
    
                        if (action === 'checkout') {
                            window.location = `${SITE_URL}/tienda/carrito`;
                        } else {
                            $('#mdlPlan').modal('hide');
                        }
                    }

                    if (res.msg) showAlert(res.msg, 'warning');

                    this.disabledBtns = false;

                    window.fbq('trackCustom',  'AddCart', {currency: "MXN", value: this.item.price });
                    window.ga('event', 'add_to_cart', {"items":[{ "id": this.item.service_id, "color": this.selectedColor, "currency": "MXN"}]});
                    // console.log(this.item.price);

                })
                .fail((error) => {
                    this.disabledBtns = false;
                    showAlert(error.responseJSON ? error.responseJSON : error.statusText, 'danger');
                });
        },
        formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
            try {
                decimalCount = Math.abs(decimalCount);
                decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

                const negativeSign = amount < 0 ? "-" : "";

                let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
                let j = (i.length > 3) ? i.length % 3 : 0;

                return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
            } catch (e) {
                console.log(e)
            }
        }   
    },
    components: {
        loadingData: LoadingDataComponent
    }
}
</script>