<template>
    <div class="row align-items-start justify-content-center shopping-cart-detail mt-5">
        <loading-data v-if="isLoding" size="medium" txt="Cargando información...."></loading-data>

        <no-information-component v-else-if="!isLoding && errorGetData" title="¡Ups!" :text="errorGetData"></no-information-component>

        <template v-else-if="!isLoding && !errorGetData && shoppingCart">

            <div class="col-sm-12 col-lg-8">
                <list-item v-for="(item, kItem) in shoppingCart.items" :key="kItem" :item="item" v-on:update-cart="doUpdateCart"></list-item>
            </div>

            <div class="col-sm-12 col-lg-1 mb-3"></div>

            <div class="col-sm-12 col-lg-3 info-total">
                        
                <div class="row">
                    <div class="col-6 text-left">
                        <strong>Subtotal</strong>
                    </div>
                    <div class="col-6 text-right">$ {{ formatMoney(shoppingCart.subtotal) }}</div>
                </div>

                <div  v-if="shoppingCart.discount" class="row">
                    <div class="col-6 text-left">
                        <strong>Descuento</strong>
                    </div>
                    <div class="col-6 text-right">-$ {{ formatMoney(shoppingCart.discount) }}</div>
                </div>

                <div  v-if="shoppingCart.shipping"  class="row">
                    <div class="col-6 text-left">
                        <strong>Envío</strong>
                    </div>
                    <div class="col-6 text-right">$ {{ formatMoney(shoppingCart.shipping) }}</div>
                </div>

                <hr>

                <div class="row">
                    <div class="col-6 text-left">
                        <strong>Total</strong>
                    </div>
                    <div class="col-6 text-right">
                    <strong>$ {{ formatMoney(shoppingCart.total) }}</strong>
                    </div>
                </div>

                <div>
                     <a href="/tienda/check" class="btn btn-block btn-success mt-2">¡Confirmar compra!</a>
                </div>

                <!-- <div id="alert-system" class="alert alert-danger alert-dismissible fade show" role="alert">
                    
                    <div class="row align-items-start">
                        <div class="col-2 col-md-1 col-lg-1 text-center">
                            <i class="alert-icon"></i>
                        </div>

                        <div id="alert-system-msg" class="col-10 col-md-11 col-lg-11">
                            ALERT
                        </div>
                    </div>

                    <button id="close-alert-system" type="button" class="close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
 -->

                <div class="form-group my-3" v:if="!shoppingCart.discount">
                    <div class="input-group mb-2 mr-sm-2">
                        
                        <input type="text" placeholder="Cupón de Descuento"  v-model="coupon" id="cupon" name="cupon" class="form-control col-md-12">
                        <div class="input-group-append">
                          <div class="input-group-text">
                              <i v-bind:class="codeClass" title="Validar" v-on:click="validCode();" style="cursor: pointer; display: block;"></i>
                          </div>
                        </div>
                    </div>
    
                </div>
               
                <a href="/tienda/index" class="btn btn-block btn-outline-secondary">Regresar a la tienda</a>
            </div>

        </template>

        <template v-else>
            <no-information-component title="Carrito vacio" text="No has agregado ningun elemento a tu carrito"></no-information-component>
           
            <div class="col-12 mt-2">
                <a href="/tienda/index" class="btn btn-outline-secondary">Ir a la tienda</a>
            </div>
        </template>
        

    </div>
</template>
<script>
import ListItemsComponent from './ListItemsComponent.vue';
import LoadingDataComponent from '../LoadingDataComponent';

export default {
    data() {
        return {
            shoppingCart: null,
            isLoding: true,
            errorGetData: null,
            // validCode: false,
            coupon: '',
            codeClass: 'fas fa-check',
        }
    },
    mounted() {
        getRequestAjax('/api/shoppingCart')
            .done((res) => {
                this.shoppingCart = res.data ? res.data : null;
                this.isLoding = false;
                this.errorGetData = null;
			})
			.fail((error) => {
                this.shoppingCart = null;
                this.isLoding = false;
                this.errorGetData = null;
                
                if (error.status === 500) {
                    this.errorGetData = 'Lo sentimos, hay un error de nuestro lado, estamos trabajando para solucionarlo.';
                } else {
                    this.errorGetData = 'Hubo un problema al tratar de obtener los datos, intenta recargar las página por favor.';
                    showAlert(error.responseJSON ? error.responseJSON : error.statusText, 'danger');
                }
			});
    },
    methods: {
        doUpdateCart(data) {
            this.shoppingCart = data;
        },
        formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
            try {
                decimalCount = Math.abs(decimalCount);
                decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

                const negativeSign = amount < 0 ? "-" : "";

                let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
                let j = (i.length > 3) ? i.length % 3 : 0;

                return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
            } catch (e) {
                console.log(e)
            }
        }  ,
        validCode(event){
        
            getRequestAjax('/api/validate_coupon', 'post', { coupon: this.coupon })
                .done((res) => {
                    if (typeof res!="undefined") {
                        if(res.success == true){
                            this.codeClass = 'fas fa-check text-success';
                            this.updateCart();
                            showAlert('Cupón aplicado en tu cuenta.', 'success');

                        } else {
                            this.codeClass = 'fas fa-times text-danger';
                            showAlert('Cupón no válido', 'danger');
                        }
                    }
                })
                .fail((err) => {
                    console.log('err');
                    console.log(err);
                });

                
        },
        updateCart(){
            getRequestAjax('/api/shoppingCart')
            .done((res) => {
                this.shoppingCart = res.data ? res.data : null;
                this.isLoding = false;
                this.errorGetData = null;
            })
            .fail((error) => {
                this.shoppingCart = null;
                this.isLoding = false;
                this.errorGetData = null;
                
                if (error.status === 500) {
                    this.errorGetData = 'Lo sentimos, hay un error de nuestro lado, estamos trabajando para solucionarlo.';
                } else {
                    this.errorGetData = 'Hubo un problema al tratar de obtener los datos, intenta recargar las página por favor.';
                    showAlert(error.responseJSON ? error.responseJSON : error.statusText, 'danger');
                }
            });
        }
         
    },
    components: { 
        listItem: ListItemsComponent,
        loadingData: LoadingDataComponent
    }
}
</script>