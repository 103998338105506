<template>
    <div class="row align-items-start cont-items mb-2 item-info">

        <div class="col-sm-12 col-lg-6 mb-2">
            <div class="row">
                <div class="col-1">
                    <i v-on:click="onEditItem('delete')" class="icon-delete fas fa-trash-alt"></i>
                </div>
                <div class="col-3">
                    <img :src="'/images/compras/' + item.image" class="img-thumbnail thumbnail-item">
                </div>
                <div class="col-7 text-left item-info">
                    <h4 class="m-0">{{ item.name }}</h4>
                    <p class="m-0 p-0">$ {{ formatMoney(item.price) }}</p>
                    <p class="m-0 p-0">Color: {{ item.detail.color }}</p>
                </div>
            </div>
        </div>
 
        <div class="col-sm-12 col-lg-6">

            <div class="row align-items-center align-items-lg-start">
                <div class="col-7 text-center">
                    <div class="row align-items-center">

                        <div class="col-sm-3 text-center rounded">
                            <button type="button" class="btn btn-edit-qty btn-block" style="padding: 2px 0px;color: #092D4D;font-weight: bold;font-size: 20px;" v-on:click="onEditQtyBtn('subtract')">-</button>
                        </div>

                        <div class="col-sm-6">
                            <input v-model="qtyValue" type="number" min="0" step="1" class="form-control input-qty text-center" v-on:input="onInputQty">
                        </div>

                        <div class="col-sm-3 text-center rounded">
                            <button type="button" class="btn btn-edit-qty btn-block" style="padding: 2px 0px;color: #092D4D;font-weight: bold;font-size: 20px;" v-on:click="onEditQtyBtn('plus')">+</button>
                        </div>

                    </div>
                </div>
                <div class="col-5 text-right item-subtotal">
                    $ {{ formatMoney(item.total) }}
                </div>
            </div>

        </div>

    </div>
</template>

<script>
export default {
    props: { 
        item: { type: Object, required: true }
    },
    data() {
        return {
            qtyValue: this.item.qty
        }
    },
    methods: { 
        onEditQtyBtn(action) {
            if ( action == 'subtract' ) {
			    if (this.qtyValue == 1) return;
                else this.qtyValue--;

                this.onEditItem('edit');
            } else if ( action == 'plus' ) {
                this.qtyValue++;

                this.onEditItem('edit');
            } else {
                return;
            }
        },
        onInputQty() {
            this.qtyValue = +this.qtyValue;
            this.onEditItem('edit');
        },
        onEditItem(action) {
            if (this.qtyValue === '' || this.qtyValue <= 0) return;

             const obj = {
                action: action,
                data: JSON.stringify({
                    detail_id: this.item.detail_id,
                    qty: this.qtyValue,
                    product_id: this.item.product_id
                })
            };

            getRequestAjax('/api/updateShopping', 'post', obj)
                .done((res) => {
                    if (res.data) {
                        console.log(res.data);
                        this.$emit('update-cart', res.data);
                    } else {
                        showAlert(res.msg, 'warning');
                    }
                })
                .fail((error) => {
                    console.log('err');
                    console.log(error);

                    if (error.status === 500) {
                        showAlert('Lo sentimos, hay un error de nuestro lado, estamos trabajando para solucionarlo.', 'danger');
                    } else {
                        showAlert(error.responseJSON ? error.responseJSON : error.statusText, 'danger');
                    }
                });
        },
        formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
            try {
                decimalCount = Math.abs(decimalCount);
                decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

                const negativeSign = amount < 0 ? "-" : "";

                let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
                let j = (i.length > 3) ? i.length % 3 : 0;

                return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
            } catch (e) {
                console.log(e)
            }
        }   

    }
}
</script>