window.getRequestAjax = (url, type = 'get', obj = null) => {
    if (type === 'post' && typeof obj !== 'object') {
        console.error('The data to send does not exist or is not valid');

        return null;
    }

    return $.ajax({
        url: url,
        method: type,
        data: obj,
        dataType: "json"
    });
}

/**
 * Show alert system
 */
window.showAlert = (msg, type='info', delay=5500) => {
    const alert = document.getElementById('alert-system');
    const alertMsg = document.getElementById('alert-system-msg');

    if (alert && alertMsg) {
        alertMsg.innerHTML = msg;
        
        alert.classList.add('alert-' + type, 'show');

        setTimeout(() => hideAlert(), delay);
    } else {
        console.warn(`Error Alert - no existe el elemento en el DOM.`);
    }
}

/**
 * Hide alert system
 */
window.hideAlert = () => {
    const classAlert = ['alert-primary', 'alert-secondary', 'alert-success', 'alert-danger', 'alert-warning', 'alert-info', 'alert-light', 'alert-dark']
    const alertSystem = document.getElementById('alert-system');

    alertSystem.classList.remove('show');

    setTimeout(() => {
        classAlert.forEach(el => {
            if (alertSystem.classList.contains(el)) {
                alertSystem.classList.remove(el);
            }
        });
    }, 500);
}


/**
 * Regular expression
 */
window.exprText = (string, type = '') => {
    if (string) {
        if ( type === 'email' ) {
            const expr = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
            return expr.test(string);
        } else if ( type === 'number' ) {
            const expr = /^[0-9]*$/;
            return expr.test(string);
        } else if ( type === 'url' ) {
            const expr = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
            return expr.test(string);
        } else if ( type === 'tel' ) {
            const expr = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
            return expr.test(string);
        } else if ( type === 'cardnumber' ) {
            const expr = /[0-9]{13,16}/;
            return expr.test(string);
        } else if ( type === 'expiredate' ) {
            const expr = /^(0[1-9]|1[0-2])\/?([0-9]{4})$/;
            return expr.test(string);
        } else if ( type === 'cvc' ) {
            const expr = /^[0-9]{3,4}$/;
            return expr.test(string);
        } else {
            return string.trim() !== '';
        }
    } else {
        return false;
    }
}