<template>
    <div class="mt-5">
        <img class="svg-noInfo" src="/images/web/empty-data.svg" alt="" srcset=""> 

        <h3 v-if="title" class="title-noInfo">{{ title }}</h3>
        <p v-if="text" class="text-noInfo">{{ text }}</p>
    </div>
</template>

<script>
export default {
    props: { 
        title: { type: String },
        text: { type: String }
    }
}
</script>
<style scoped>
    .svg-noInfo {
        width: 250px;
        height: auto;
    }

    @media only screen and (min-width: 450px) {
        .svg-noInfo {
            width: 400px;
            height: auto;
        }
    }

    .title-noInfo {
        margin-top: 12px;
        margin-bottom: 0;
        padding: 0;
    }

    .text-noInfo {
        margin-top: 12px;
        margin-bottom: 0;
        color: gray;
        padding: 0;
    }
</style>