<template>
    <div>
        <div class="form-group only-one-plan">
            <label for="">Selecciona el color: <span class="plan-color-text">{{ selectedColor }}</span></label>
            <div class="cont-colors">
                <div v-for="(color, keyColor) in colors" :key="keyColor" class="cont-color" :class="{ 'active-color': color.color === selectedColor }" v-on:click="selectedColor = color.color">
                    <div data-color="Negro" class="plan-color color" :class="[color.css]"></div>
                </div>
            </div>
        </div>
        <button v-on:click="onAddCart" :disabled="disabledBtns" type="button" style="width: 100%;display: block;" class="btn btn-success btn-lg">
            Adquirir
        </button>
    </div>
</template>
<script>
import EventBus from '../bus';

export default {
    props: {
        item: {type: String, required: true},
    },
    data() {
        return {
            disabledBtns: false,
            colors: [
                { color: 'Negro', css: 'black' },
                { color: 'Rosa', css: 'pink' },
                { color: 'Azul', css: 'blue' },
                { color: 'Verde', css: 'green' }    
            ],
            selectedColor: 'Negro',
        }
    },
    methods: {
        onAddCart(action) {
            if (!this.item) return;

            this.disabledBtns = true;

            const obj = {
                action: 'add',
                data: JSON.stringify({
                    product_id: this.item,
                    type: 'service',
                    detail: JSON.stringify({ color: this.selectedColor }),
                    qty: 1,
                })
            };

            getRequestAjax('/api/updateShopping', 'post', obj)
                .done((res) => {
                    if (res.data) {
                        // EventBus.$emit('update-cart', res.data);
                        window.location = `${SITE_URL}/tienda/carrito`;
                        showAlert('Elmento agregado a tu carrito.', 'success');
                    }

                    if (res.msg) showAlert(res.msg, 'warning');

                     this.disabledBtns = false;

                })
                .fail((error) => {
                    this.disabledBtns = false;
                    showAlert(error.responseJSON ? error.responseJSON : error.statusText, 'danger');
                });
        }
    }
}
</script>