<template>
    <div class="cont-btn-shopping-cart dropup">
        <div v-if="errorGetData" class="box-error-cart">
            ¡Ups!, no se logro obtener la información de tu carrito, da clic a 
            <strong><a href="/tienda/carrito" class="text-danger">Editar mi carrito</a></strong>, para obtener una información detallada.
        </div>
        <template v-else-if="!errorGetData && myShoppingCart">
            <div id="drop-shopping-cart" class="dropdown-menu">
                <h6 class="dropdown-header">Mi carrito</h6>

                <div class="dropdown-item">
                    <div class="row align-items-start">
                        <div v-for="(item, kItem) in myShoppingCart.items" :key="kItem" class="col-12 text-left">
                            <p>{{ item.name }}</p>
                            <div class="row">
                                <div class="col-6 text-left">
                                    {{ item.qty }} x $ {{ formatMoney(item.price) }}
                                </div>
                                <div class="col-6 text-right">
                                    $ {{ formatMoney(item.total) }}
                                </div>
                            </div>

                            <div class="dropdown-divider"></div>
                        </div>
                    </div>
                </div>
                
            </div>

            <div class="icon-cart">
                <i v-on:click="goToCart('')" v-on:mouseover="viewDropDown('enter')" v-on:mouseleave="viewDropDown('leave')" class="fas fa-shopping-basket"></i>
            </div>

            <div class="info-cart">
                <div class="cart-total">
                    Total <span class="cart-total">$ {{ formatMoney(myShoppingCart.total) }}</span>
                </div>
                <div class="cart-link-detail">
                    <a href="/tienda/carrito">Editar carrito</a>
                </div>
            </div>
        </template>
        <span v-else></span>
           
	</div>
</template>

<script>

    
import EventBus from '../bus';

export default {
    data() {
        return {
            myShoppingCart: null,
            activeDropdown: false,
            errorGetData: false,
        }
    },
    mounted() {
        this.getCartData();
    },
    created() { 
        EventBus.$on('update-cart', (cart) => {
            if (cart) this.myShoppingCart = cart;
            else this.myShoppingCart = null;
        });
    },
    methods: {
        viewDropDown(action) {
            this.activeDropdown = action === 'click' ? !this.activeDropdown : action === 'enter';

            $('#drop-shopping-cart').dropdown(this.activeDropdown ? 'show' : 'hide');
        },

        goToCart(){
            window.location.href = '/tienda/carrito';
        },
        getCartData() {
            getRequestAjax('/api/shoppingCart')
                .done((res) => {
                    this.myShoppingCart = res.data ? res.data : null;
                    this.errorGetData = false;
                })
                .fail((error) => {
                    this.myShoppingCart = null;
                    this.errorGetData = true;

                    showAlert(error.responseJSON ? error.responseJSON : error.statusText, 'danger');
                });
        }, 
        formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
            try {
                decimalCount = Math.abs(decimalCount);
                decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

                const negativeSign = amount < 0 ? "-" : "";

                let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
                let j = (i.length > 3) ? i.length % 3 : 0;

                return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
            } catch (e) {
                console.log(e)
            }
        }   

    }
}
</script>

<style>

.box-error-cart {
    background-color: white;
    padding: 10px;
    border: 1px solid #c7c7c7;
    border-radius: 4px;
    box-shadow: 1px 1px 5px #bbbbbb;
}
</style>