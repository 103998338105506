window._ = require('lodash');

import '../../node_modules/bootstrap-datepicker/dist/js/bootstrap-datepicker.js';

window.Popper = require('popper.js').default;
try {
	window.$ = window.jQuery = require('jquery');

	/**
	 * Carga de scripts bootstrap
	 */
	require('bootstrap');

	/**
	 * Carga de libreria tempusdominus
	 */
} catch (e) {
	console.log('No jquery');
	console.error(e);
}

if ('serviceWorker' in navigator) {
	navigator.serviceWorker.getRegistrations().then(
	
	   function(registrations) {
	
		   for(let registration of registrations) {  
			   registration.unregister();
	
		   }
	
	});
}


$('#video_modal').on('hide.bs.modal', function (e) {
	var $v = $(e.delegateTarget).find('video');
	$v[0].pause();  // use [0] because jQuery returns a list
});
$('#video_modal').on('show.bs.modal', function(e) {
	var $v = $(e.delegateTarget).find('video');
	$v[0].play();  // use [0] because jQuery returns a list
});

// import './drawer/js/index.js';

require('./scripts');

const appWebIdAnimalElement = document.getElementById('app-web-idanimal');
const btnCloseAlertSystem = document.getElementById('close-alert-system');


if (appWebIdAnimalElement !== null) {
	require('./vue/components');
}

if (btnCloseAlertSystem) {
	btnCloseAlertSystem.addEventListener('click', () => hideAlert());
}

import Splide from '@splidejs/splide';

var elms = document.getElementsByClassName( 'splide' );
	for ( var i = 0, len = elms.length; i < len; i++ ) {
		new Splide( elms[ i ] , {
			type   : 'loop',
			perPage: 3,
		}  ).mount();
	}

// new Splide( '.splide', {type   : 'loop',
					// perPage: 3} ).mount();
// new Splide( '.splide2' ).mount();

