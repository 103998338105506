<template>
    <div class="container-loader" :class="{'display-inline': displayInline}">
        <div class="sk-chase" :class="[size]">
            <div class="sk-chase-dot" :class="[color]"></div>
            <div class="sk-chase-dot" :class="[color]"></div>
            <div class="sk-chase-dot" :class="[color]"></div>
            <div class="sk-chase-dot" :class="[color]"></div>
            <div class="sk-chase-dot" :class="[color]"></div>
            <div class="sk-chase-dot" :class="[color]"></div>
        </div>
        <p v-if="txt !== ''" class="text-muted text-loading" :class="{'p-display-inline': displayInline}">{{ txt }}</p>
        
    </div>
</template>

<script>
export default {
    props: {
        txt: {type: String, required: false, default: ''},
        size: {type: String, required: false, default: 'small'},
        color: {type: String, required: false, default: 'primary'},
        displayInline: {type: Boolean, required: false, default: false}
    }
}
</script>
<style lang="css" scoped>
    .container-loader {
        width: 100%;
    }
    .display-inline {
        display: inline-block;
        min-width: 50px;
        width: auto;
        text-align: center;
    }
    p.p-display-inline {
        display: inline-block;
        margin-top: 0;
        margin-bottom: 0;
        padding: 0;
    }
    .text-loading {
        margin-top: 17px;
        font-size: 16px;
    }
    .sk-chase {
        position: relative;
        animation: sk-chase 2.5s infinite linear both;
        display: inline-block;
        margin-left: 5px;
        margin-right: 5px;
    }

    .sk-chase-dot {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0; 
        animation: sk-chase-dot 2.0s infinite ease-in-out both; 
    }

    .sk-chase-dot:before {
        content: '';
        display: block;
        width: 25%;
        height: 25%;
        border-radius: 100%;
        animation: sk-chase-dot-before 2.0s infinite ease-in-out both; 
    }

    .primary:before {
        background-color: #01305c;
    }
    .light:before {
        background-color: #FFF;
    }

    .sk-chase-dot:nth-child(1) { animation-delay: -1.1s; }
    .sk-chase-dot:nth-child(2) { animation-delay: -1.0s; }
    .sk-chase-dot:nth-child(3) { animation-delay: -0.9s; }
    .sk-chase-dot:nth-child(4) { animation-delay: -0.8s; }
    .sk-chase-dot:nth-child(5) { animation-delay: -0.7s; }
    .sk-chase-dot:nth-child(6) { animation-delay: -0.6s; }
    .sk-chase-dot:nth-child(1):before { animation-delay: -1.1s; }
    .sk-chase-dot:nth-child(2):before { animation-delay: -1.0s; }
    .sk-chase-dot:nth-child(3):before { animation-delay: -0.9s; }
    .sk-chase-dot:nth-child(4):before { animation-delay: -0.8s; }
    .sk-chase-dot:nth-child(5):before { animation-delay: -0.7s; }
    .sk-chase-dot:nth-child(6):before { animation-delay: -0.6s; }

    @keyframes sk-chase {
        100% { transform: rotate(360deg); } 
    }

    @keyframes sk-chase-dot {
        80%, 100% { transform: rotate(360deg); } 
    }

    @keyframes sk-chase-dot-before {
        50% {
            transform: scale(0.4); 
        } 100%, 0% {
            transform: scale(1.0); 
        } 
    }
    .small {
        width: 15px;
        height: 15px;
    }
    .medium {
        width: 40px;
        height: 40px;
    }
    .large {
        width: 70px;
        height: 70px;
    }
</style>