<template>
    <div class="w-100 step-payment text-left">
        <div class="row cont-options-pay">
            <div v-for="(typePayment, kTypePayment) in paymentMethods" :key="kTypePayment" class="col-sm-12 col-md-4">
                <div class="image" :class="{'paymet-selected': typePayment.type == paymentSelected}" v-on:click="paymentSelected = typePayment.type">
                    <img :src="'/images/web/' + typePayment.img">
                </div>
            </div>

            <div v-if="paymentSelected === 'spei' || paymentSelected === 'oxxo'" class="container-info mb-3 mt-4 text-center">
                <span class="d-block text-left" v-if="paymentSelected === 'spei'">
                    Se generará automáticamente una <strong>CLABE interbancaria</strong> la cual deberás dar de alta en tu banca en línea y realizar tu pago.
                </span>
                <span class="d-block text-left" v-else>
                    Se generará un <strong>número de referencia</strong> con el que deberás acudir al OXXO de tu preferencia, proporcionar la referencia en caja y realizar el pago. Una vez confirmado el pago recibirás una notificación.
                </span>
                <span class="d-block text-left mb-3">
                    En el detalle de la compra se listan las instrucciones de cómo realizar el pago y {{ paymentSelected === 'spei' ? 'la CLABE interbancaria generada' : 'el número de referencia generado' }}.
                </span>

                <div class="w100 text-right">
                    <button type="button" v-on:click="onValidStep" :disbaled="(btnDisabled || processData)" class="btn btn-secondary mt-2">
                        <loading-data v-if="processData" :display-inline="true" color="light" :txt="'Generando ' + (paymentSelected === 'oxxo' ? 'CLABE' : 'referencia') "></loading-data> 
                        <span v-else>Seleccionar y continuar</span>
                    </button>
                </div>

            </div>

            <div v-if="paymentSelected === 'card'" class="form-row mb-3 mt-4">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label for="titular">Nombre del titular</label>
                        <input v-model="mdlCard.name" type="text" minlength="5" class="form-control" :class="{'is-invalid': validCard.name === false}" id="titular" :disabled="processData" v-on:blur="onValidName()">
                        <span v-if="validCard.name === false" class="text-danger">Ingresa el nombre del titular por favor</span>
                    </div>
                </div>

                <div class="col-sm-6">
                    <div class="form-group">
                        <label for="card"># de tarjeta</label>
                        <input v-model="mdlCard.number" type="number" minlength="14" size="16" maxlength="16" class="form-control" :class="{'is-invalid': validCard.number === false}" id="card" :disabled="processData" v-on:blur="onValidNumberCard()" >
                        <span v-if="validCard.number === false" class="text-danger">El número de tarjeta no es válido</span>
                    </div>
                </div>

                <div class="col-sm-12 col-md-6">
                    <div class="form-group">
                        <label for="expire_date">Fecha de vencimiento</label>
                        <input  v-model="mdlCard.exp_date" type="tel" class="form-control" :class="{'is-invalid': validCard.exp_date === false}" id="expire_date" minlength="7" maxlength="7" placeholder="00/0000" v-on:input="onMonth($event)" :disabled="processData" v-on:blur="onValidExpDate()">
                        <span v-if="validCard.exp_date === false" class="text-danger">La fecha de expiración no es válida</span>
                    </div>
                </div>

                <div class="col-sm-12 col-md-6">
                    <div class="form-group">
                        <label for="cvc">CVC</label>
                        <input v-model="mdlCard.cvc" type="tel" class="form-control" :class="{'is-invalid': validCard.cvc === false}" minlength="3" min="0" maxlength="4" id="cvc" placeholder="000" :disabled="processData" v-on:blur="onValidCvc()">
                        <span v-if="validCard.cvc === false" class="text-danger">El CVC no es válido</span>
                    </div>

                </div>

                <div class="col-sm-12 col-md-12">
                    <div class="form-group">
                        <label for="meses">¿Deseas pagar a meses sin intereses?</label>
                    </div>
                </div>
                <div class="custom-control custom-checkbox col-sm-12 col-md-12">
                    <div v-for="mes in this.arr_meses">
                        
                        <input v-model="meses" name="meses" type="radio" class="" :value="''+mes" :id="'meses' +mes" >
                        <label :for="'meses' +mes" v-if="mes > 1"> {{ mes }} meses sin intereses</label>
                        <label :for="'meses' +mes" v-else> Un solo cargo</label>
                        
                    </div>
                </div>

                <div class="col-sm-12 text-right">
                    <button type="button" v-on:click="onValidStep" :disbaled="(btnDisabled || processData)" class="btn btn-secondary mt-2">
                        <loading-data v-if="processData" :display-inline="true" txt="Procesando pago" color="light"></loading-data> 
                        <span v-else>Continuar</span>
                    </button>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
import LoadingDataComponent from '../../LoadingDataComponent.vue';

export default {
    props: {
        processData: { type: Boolean, default: false}
    },
    data() {
        return {
            isConectingConekta: false,
            paymentMethods: [
                { type: 'spei', img: 'spei.png' },
                { type: 'oxxo', img: 'oxxo.png' },
                { type: 'card', img: 'card.png' }
            ],
            paymentSelected: null,
            btnDisabled: false,
            mdlCard: { 
                number: '',
                name: '',
                exp_date: '',
                cvc: ''
            },
            validCard: {
                number: null,
                name: null,
                exp_date: null,
                cvc: null
            },
            arr_meses: [1, 3,6,9,12],
            meses: '1'
        }
    },
    methods: {
        onValidStep() {
            if (this.paymentSelected === 'card') {
                this.onValidName();
                this.onValidNumberCard();
                this.onValidExpDate();
                this.onValidCvc();

                if ( !this.validCard.name || !this.validCard.number || !this.validCard.exp_date || !this.validCard.cvc  ) {
                    return;
                }
            }
            this.btnDisabled = false;

            const obj = {
                step: 'payment',
                data: {
                    'method': this.paymentSelected,
                    'meses': this.meses,
                    'card': JSON.stringify(this.mdlCard)
                }
            };
            
            this.$emit('nextStep', obj);
        },
        onMonth(event) {
            const value = event.target.value;

            if (value.length === 2) {
                this.mdlCard.exp_date = value + '/';
            }
        },
        onValidName() {
            this.validCard.name = this.mdlCard.name.length > 5;
        },
        onValidNumberCard() {
            this.validCard.number = this.typeCard();
        },
        onValidExpDate() {
            this.validCard.exp_date = this.dateCard();
        },
        onValidCvc() {
            const expCvc = new RegExp("^[0-9]{3,4}$");
            this.validCard.cvc = expCvc.test(this.mdlCard.cvc) && ( this.mdlCard.cvc.length === 3 || this.mdlCard.cvc.length === 4 );
        },
        dateCard() {
            if (this.mdlCard.exp_date.trim() === '') {
                return false;
            } else {
                const month = +this.mdlCard.exp_date.split('/')[0];
                const year = +this.mdlCard.exp_date.split('/')[1];
    
                const nowYear = new Date().getFullYear();
                const nowMoth = new Date().getMonth();
    
                if (year < nowYear) {
                    return false;
                } else if (year === nowYear && nowMoth > month) {
                    return false;
                } else if (month < 0 || month > 12) {
                    return false;
                } else {
                    return true;
                }
            }
        },
        typeCard() {
            const number = this.mdlCard.number.toString();

            let re = new RegExp("^4");

            if (number.match(re) != null)
                return true;

            // Visa Electron
            re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
            if (number.match(re) != null)
                return true;

            // Mastercard
            re = new RegExp("^5[1-5]");
            if (number.match(re) != null)
                return true;

            // AMEX
            re = new RegExp("^3[47]");
            if (number.match(re) != null)
                return true;

            return false;
        }
    },
    components: {
        loadingData: LoadingDataComponent
    }
}
</script>