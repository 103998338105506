<template>

    <div class="col-md-4  mb-3" >
        <div class="card card-plans" :id="item.service_alias">
        	<!-- <div class="buen_fin" style="position: absolute; top: -15px; right: -15px;  display: block; z-index: 8;">
                <img src="/images/web/buen_fin/logo_buenfin_web_2020.png" alt="Logo Buenfin 2020" style="width: 120px">
            </div>
     -->
    	   <img class="card-img-top" :src="'/images/compras/' + item.image_small">
    	
    		<div class="card-body py-0">
    			<h4 class="card-title mt-1 pt-1 mb-0" >{{ item.name }}</h4>
    			<p class="card-text mt-2 pt-0" v-html="item.brieff"></p>

    		</div>
            <div class="card-footer pt-0 pb-4">
                <h6 class="text-center">Por tan sólo</h6>
                <!-- <h4 v-if="item.text_price_other != ''" class="price-text text-center pt-0 pb-1 mb-0" style="text-decoration: line-through;">{{ item.text_price_other }}</h4> -->
                <h4 class="pt-0 pb-1 mb-0 text-center"><strong>{{ item.text_price }}</strong> </h4>
                <h6 :if="item.text_partial_price" class="text-center">{{ item.text_partial_price }}</h6>

                <div class="text-center">
                    <button type="button" class="btn btn-success btn-mdl-plan btn-comprar text-center" :disabled="disableBtn" v-on:click="onEmmitEvent">
                        <i v-if="disableBtn" class="fas fa-spinner fa-spin"></i>
                        <span v-else class="btn-text-mdl-plan ">Comprar</span>
                    </button>
                </div>

            </div>
        </div>
	</div>
</template>
<script>
export default {
    props: {
        item: { type: Object, required: true },
        disableBtn: { type: Boolean, required: true}
    },
    data() {
        return {}
    },
    methods: {
        onEmmitEvent() {
            this.$emit('onShowItem', this.item);
        }
    }
}
</script>