<template>
    <div class="row">

        <div class="container d-flex flex-column align-items-center">
            <h2 class="font-weight-bold text-center" style=" font-size:xx-large;">La tranquilidad de estar protegido</h2>
            <h3 class="font-weight-bold  text-center text-primary display-4" style="color:#C2202B;">PLANES</h3>
            <h3 class=" text-center">¡Elige el que más te convenga!</h3>
            <h2 class="text-center" style=" font-size:large;"> 12 meses sin intereses con todas las tarjetas de crédito</h2>



            <div class="col-md-12 row no-gutters d-ms-flex justify-self-center justify-content-center align-items-start pt-5">


                <loading-data v-if="isLoding" size="medium" txt="Obteniendo planes..."></loading-data>

                <no-information-component 
                    v-else-if="!isLoding && !items.length" 
                    title="¡Ups!" 
                    :text="errorGetData ? 'Hubo un problema al tratar de obtener los datos, intenta recargar las página por favor.' : 'Por el momento no hay planes activos'"></no-information-component>

                <template v-else>
                    <care-plan-component v-for="(item, keyId) of items" :key="keyId" :item="item" :disable-btn="disabledBtns" @onShowItem="onShowModal"></care-plan-component>

                    <care-plan-mdl v-if="itemSelected" :item="itemSelected"></care-plan-mdl>
                </template>

            </div>
        </div>

    </div>
</template>
<script>
import CarePlanItemComponent from './CarePlanItemComponent.vue';
import CarePlanModalComponent from './CarePlanModalComponent.vue';
import LoadingDataComponent from '../LoadingDataComponent.vue';

export default {
    data() {
        return {
            items: [],
            itemSelected: null,
            disabledBtns: false,
            isLoding: true,
            errorGetData: false
        }
    },
    mounted() {
        getRequestAjax('/api/carePlans')
            .done((res) => {
                this.isLoding = false;
                this.items = res.data;
                this.itemSelected = null;
                this.errorGetData = false;

                if (res.msg) showAlert(res.msg, 'warning');
			})
			.fail((error) => {
                this.items = [];
                this.itemSelected = null;
                this.isLoding = false;
                this.errorGetData = true;

                showAlert(error.responseJSON ? error.responseJSON : error.statusText, 'danger');
			});
    },
    methods: { 
        onShowModal(data) {
            this.disabledBtns = true;
            this.itemSelected = data;

            setTimeout(() => {
                $('#mdlPlan').modal('show');

                $('#mdlPlan').on('hidden.bs.modal', (e) => {
                    this.disabledBtns = false;
                    this.itemSelected = null;
                });
            }, 400);
        }
    },
    components: {
        carePlanComponent: CarePlanItemComponent,
        carePlanMdl: CarePlanModalComponent,
        loadingData: LoadingDataComponent
    }
}
</script>