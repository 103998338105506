/**
 * Carga de libreria y componentes de VUE
 */
window.Vue = require('vue');

Vue.component('care-plans-component', require('./components/CarePlanComponents/CarePlansComponent.vue').default);
Vue.component('shoppingcart-item-component', require('./components/ItemCartComponent.vue').default);
Vue.component('edit-shoppingcart-component', require('./components/ShoppingCartComponents/EditShoppingcartComponent.vue').default);
Vue.component('checkout-shoppingcart-component', require('./components/CheckupCartComponent/CheckupCartComponent.vue').default);
Vue.component('no-information-component', require('./components/NoInfoComponent.vue').default);
Vue.component('button-add-item', require('./components/ButtonAddPlanComponent.vue').default);

const appWeb = new Vue({
	el: '#app-web-idanimal'
});