<template>
    <nav>
        <ol class="cd-multi-steps text-top">
            <li v-for="(step, kStep) in steps" :key="kStep" :class="{ 'visited': onVisited(step.step), 'current': step.step === currStep }">
                <!-- <a href="#">Mi cuenta</a> -->
                <em>{{ step.label }}</em>
            </li>
        </ol>
    </nav>
</template>
<script>
export default {
    props: {
        steps: { type: Array, required: true },
        currStep: { type: String },
        completedSteps: { type: Array }
    },
    methods: {
        onVisited(step) {
            return this.completedSteps.indexOf(step) !== -1;
        },
    }
}
</script>