<template>
    <div class="row align-items-start justify-content-center checkout-shopping-cart mt-5">
        
        <loading-data v-if="isLoading" size="medium" txt="Cargando información..."></loading-data>

        <no-information-component v-else-if="!isLoading && errorGetData" title="¡Ups!" :text="errorGetData"></no-information-component>

        <template v-else-if="!isLoading && !errorGetData && shoppingCart">
            <div class="col-sm-12 col-lg-12">
                <stepper-component :steps="listSteps" :curr-step="currStep" :completed-steps="completedSteps"></stepper-component>
            </div>

            <div class="col-sm-12 col-lg-7">
                <component v-bind:is="componentActive" v-on:nextStep="onNextStep" :process-data="sendingData" />
            </div>

           
                        
            <div class="col-sm-12 col-lg-4 offset-lg-1 info-total">
                <items-component v-if="shoppingCart" :shop-cart="shoppingCart"></items-component>
            </div>
        </template>

        <template v-else>
            <no-information-component title="Carrito vacio" text="No has agregado ningun elemento a tu carrito"></no-information-component>
           
            <div class="col-12 mt-2">
                <a href="/tienda/index" class="btn btn-outline-secondary">Ir a la tienda</a>
            </div>
        </template>
        
    </div>

</template>
<script>
import StepperComponent from './StepperComponent.vue';
import ListItemsComponent from './ListItemsComponent.vue';
import SignComponent from './StepsComponents/SignComponent.vue';
import ShippingComponent from './StepsComponents/ShippingComponent.vue';
import PaymentMethodComponent from './StepsComponents/PaymentMethodComponent.vue';
import ConfirmationComponent from './StepsComponents/ConfirmationComponent.vue';
import LoadingDataComponent from '../LoadingDataComponent.vue';

export default {
    props: {
        isLogged: { type: Number, default: 0 }
    },
    data() {
        return {
            shoppingCart: null,
            listSteps: [
                { step: 'sign', label: 'Mi cuenta', c: 'signComponent' },
                { step: 'shipping', label: 'Envío', c: 'shippingComponent' },
                { step: 'payment', label: 'Pago', c: 'paymentMethodComponent' },
                { step: 'confirmation', label: 'Detalle de compra', c: 'confirmationComponent' }
            ],
            completedSteps: [],
            currStep: 'sign',
            componentActive: 'signComponent',
            isLoading: true,
            errorGetData: null,
            sendingData: false
        }
    },
    mounted() {
        getRequestAjax('/api/shoppingCart')
            .done((res) => {
                this.shoppingCart = res.data ? res.data : null;
                this.isLoading = false,
                this.errorGetData = null;

                if (this.isLogged === 1) {
                    if (res.steps) {
                        this.completedSteps = res.steps.split(',');
                        this.currStep = this.completedSteps[this.completedSteps.length - 1];
                        this.onChangeStep();
                    } else {
                        this.completedSteps.push('sign');
                        this.currStep = 'shipping';
                        this.componentActive = 'shippingComponent';
                    }
                }
			})
			.fail((error) => {
                this.shoppingCart = null;
                this.isLoading = false;
                
                if (error.status === 500) {
                    this.errorGetData = 'Lo sentimos, hay un error de nuestro lado, estamos trabajando para solucionarlo.';
                } else {
                    this.errorGetData = 'Hubo un problema al tratar de obtener los datos, intenta recargar las página por favor.';
                    showAlert(error.responseJSON ? error.responseJSON : error.statusText, 'danger');
                }
			});
    },
    methods: {
        onNextStep(data) {
            if (data.step === 'shipping') {
                this.stepShipping(data.data);
            } else if (data.step === 'payment') {
                this.stepPayment(data.data)
            }
        },
        stepShipping(data) {
            this.sendingData = true;

            getRequestAjax('/api/addressShippingOrder', 'post', data)
                    .done((res) => {
                        if (res.data) {
                            this.completedSteps.push(this.currStep);
                            this.onChangeStep('shipping')
                        } else {
                            showAlert(res.msg, 'warning');
                        }

                        this.sendingData = false;
                    })
                    .fail((error) => {
                        if (error.status === 500) {
                            showAlert('Lo sentimos, hay un error de nuestro lado, estamos trabajando para solucionarlo.', 'danger');
                        } else {
                            showAlert(error.responseJSON ? error.responseJSON : error.statusText, 'danger');
                        }

                        this.sendingData = false;
                    });
        },
        stepPayment(data) {
            this.sendingData = true;

            getRequestAjax('/api/payOrder', 'post', data)
                    .done((res) => {
                        if (res) {
                            if (res.data) {
                                this.sendingData = false;
                                this.completedSteps.push(this.currStep);
                                this.onChangeStep('payment');

                                showAlert(res.msg, 'success', 10000);
                            } else {
                                showAlert(res.msg, 'warning', 10000);
                                this.sendingData = false;
                            }

                        }
                    })
                    .fail((error) => {
                        if (error.status === 500) {
                            showAlert('Lo sentimos, hay un error de nuestro lado, estamos trabajando para solucionarlo.', 'danger');
                        } else {
                            showAlert(error.responseJSON ? error.responseJSON : error.statusText, 'danger', 10000);
                        }

                        this.sendingData = false;
                    });
        },
        onChangeStep() {
            const indexStep = this.listSteps.findIndex(s => s.step === this.currStep);

            if (indexStep !== -1 && (indexStep + 1) <= this.listSteps.length) {
                const nextStep = this.listSteps[indexStep + 1];

                this.currStep = nextStep.step;
                this.componentActive = nextStep.c;
            }

        }
    },
    components: {
        stepperComponent: StepperComponent,
        itemsComponent: ListItemsComponent,
        signComponent: SignComponent,
        shippingComponent: ShippingComponent,
        paymentMethodComponent: PaymentMethodComponent,
        confirmationComponent: ConfirmationComponent,
        loadingData: LoadingDataComponent
    }
 }
</script>