<template>
    <div class="w100">
        <div v-for="(item, kItem) in shopCart.items" :key="kItem" class="row align-items-start list-items mb-3">
            <div class="col-7 text-left item-info">
                <h4 class="m-0">{{ item.name }}</h4>
                <p class="m-0 p-0">{{ item.qty }} x $ {{ formatMoney(item.price) }} </p>
                <p class="m-0 p-0">Color: {{ item.detail.color }}</p>
            </div>
            <div class="col-5 text-right item-info">
                <p class="m-0 p-0">$ {{ formatMoney(item.total) }}</p>
            </div>
        </div>

        <div class="row">
            <div class="col-7 text-left">
                <strong>Subtotal</strong>
            </div>
            <div class="col-5 text-right">$ {{ formatMoney(shopCart.subtotal) }}</div>
        </div>

        <div class="row" v-if="shopCart.discount">
            <div class="col-7 text-left">
                <strong>Descuento</strong>
            </div>
            <div class="col-5 text-right">-$ {{ formatMoney(shopCart.discount) }}</div>
        </div>
        <hr>
        <div class="row">
            <div class="col-7 text-left">
                <strong>Total</strong>
            </div>
            <div class="col-5 text-right">
                <strong>$ {{ formatMoney(shopCart.total) }}</strong>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: { 
        shopCart: { required: true, type: Object }
    }, 
    methods: {
        formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
            try {
                decimalCount = Math.abs(decimalCount);
                decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

                const negativeSign = amount < 0 ? "-" : "";

                let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
                let j = (i.length > 3) ? i.length % 3 : 0;

                return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
            } catch (e) {
                console.log(e)
            }
        }   
    }
}
</script>