<template>
    <div class="mt-5 row step-register">
        <div class="col-sm-6 text-center mb-4">
            <span class="cont-icon">
                <i class="fas fa-user"></i>
            </span>
            <button class="btn btn-outline-dark" type="button" data-target="#loginModal" aria-controls="loginModal" aria-expanded="false" data-toggle="modal">Ingresar a mi cuenta</button>
        </div>
        <div class="col-sm-6 text-center">
            <span class="cont-icon">
                <i class="fas fa-user-plus"></i>
            </span>
            <button class="btn btn-outline-dark" type="button" data-target="#registerModal" aria-controls="registerModal" aria-expanded="false" data-toggle="modal">Crear una cuenta</button>
        </div>
    </div>
</template>
<script>
export default {
    
}
</script>